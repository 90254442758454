.flexyContainer {
  display: flex;
  margin: 0 -10px;
}

.flexyColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f1f1f1f1;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 50%;
}

.flexyItem {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
}

.flexyItem > b {
  margin-right: 5px;
}
