body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ibButton {
  display: block;
  width: 100%;
  padding: 5px;
  border: 1px solid lightgrey;
  background-color: white;
  color: #005060;
  text-align: center;
  border-radius: 5px;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.ibButton:disabled {
  background-color: #f2f2f2;
  color: #a0a0a0;
  border: 1px solid #d0d0d0;
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;
}

.ibSuccess {
  background-color: green;
  color: white;
}

.iEnquiry {
  background-color: #89c3ff;
}

.ibButton:hover {
  opacity: 0.5;
  cursor: pointer;
}

.ibButton:active {
  opacity: 1;
}

.ibButtonSuccess {
  color: white;
  background-color: green;
}

.ibParcelRow {
  width: 100%;
  display: flex;
  border: 1px solid lightgrey;
  padding: 5px;
}

.ibParcelItem {
  color: black;
  border-left: '1px solid lightgrey';
  margin-left: 5px;
}

.ibSmallWarn {
  background-color: #bb0000;
  color: white;
  width: auto;
  float: right;
}

.ibXRemoveButton {
  padding: 1px;
  text-align: center;
  background-color: #bb0000;
  color: white;
  width: 25px;
  height: 25px;
}
button.ibXRemoveButton {
  appearance: none;
  border: none;
}

.ibXRemoveButton:hover {
  cursor: pointer;
  opacity: 0.7;
}

.ibXRemoveButton:active {
  opacity: 1;
}

.bBoxCol {
  border: 1px solid lightgrey;
  padding: 5px;
}

.bBoxColHighlighted {
  border-color: green;
}

.ibLink {
  color: #005060;
}

.ibLink:hover {
  opacity: 0.7;
  cursor: pointer;
}

.ibTextareaInput {
  width: 75%;
  height: 200px;
}

.traceLogBox {
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 5px;
}

.traceLogMessage {
  color: #007bff;
  user-select: none;
}
.traceLogMessage:hover {
  opacity: 0.5;
  cursor: pointer;
}

.traceLogJson {
  width: 100%;
  text-align: left;
}

.textInput {
  color: black;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
}

.google-location-input {
  color: black;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 5px;
  min-width: 300px;
}

.clickOpacityHover:hover {
  cursor: pointer;
  opacity: 0.5;
}

.primaryColor {
  color: #005060;
  background-color: #005060;
}

.smallFormWidth {
  width: 35%;
}

.ibButtonSmall {
}
